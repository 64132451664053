import { Tooltip } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as StarSvg } from '../../assets/icons/star-full.svg';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { notificationSuccess, requestError } from '../../helpers/notification';
import UserService from '../../services/user.service';
import { selectModeSelected, selectPlanningSelected } from '../../redux/slices/app/planning.slice';

// This component is used for customizing Gantt appearance or behaviour
const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const FavoriteToggle = () => {
    const {t} = useTranslation('translation', {keyPrefix:'favorite'})
    const [isFavorite, setIsFavorite] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const planningSelected = useSelector(selectPlanningSelected)
    const modeSelected = useSelector(selectModeSelected);
    const { setUpdatedFolderBroadcast } = useContext(PlanningContext);

    useEffect(() => {
        const onPlanningChange = async () => {
            if (planningSelected) {
                const favorite = await UserService.isFavoritePlanning(planningSelected.id);
                setIsFavorite(favorite);
            }
        };
        onPlanningChange();
    }, [planningSelected]);

    const toggleFavorite = () => {
        setIsLoading(true);
        if (isFavorite) {
            UserService.removePlannigFromfavorite(planningSelected.id)
                .then(() => {
                    notificationSuccess( t('favorite'),t('removed_from_favorite'));
                    // update tree indicator
                    setUpdatedFolderBroadcast({
                        id: planningSelected.id,
                        type: 'Planning',
                        updatedData: { ...planningSelected, favorite: false },
                    });
                    setIsFavorite(false);
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                    requestError(e, t('favorite'));
                });
        } else {
            UserService.addPlanningToFavorite(planningSelected.id, planningSelected.name)
                .then(() => {
                    notificationSuccess(t('favorite'),t('added_to_favorite'));
                    setIsFavorite(true);
                    setUpdatedFolderBroadcast({
                        id: planningSelected.id,
                        type: 'Planning',
                        updatedData: { ...planningSelected, favorite: true },
                    });
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                    requestError(e, t('favorite'));
                });
        }
    };

    return (
        modeSelected === 'live' && (
            <div className="flex items-center h-100">
                <Tooltip title={`${isFavorite ? t('remove_favorite') : t('add_favorite')}`}>
                    <QuickButton
                        type="button"
                        className={`ant-dropdown-link inline-block text-center ${
                            isFavorite ? 'bg-black text-primary' : 'bg-primary'
                        }`}
                        onClick={(e) => toggleFavorite(e)}
                    >
                        <StarSvg
                            className={`mx-auto${isLoading ? ' animate-spin' : ''}`}
                            style={{ width: '24px', height: '24px' }}
                        />
                    </QuickButton>
                </Tooltip>
            </div>
        )
    );
};
export default FavoriteToggle;
