import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { ReactComponent as ColumnsSvg } from '../../assets/icons/columns.svg';
import ColumnParams from './drawer/ColumnParams';
import { refreshPlanningSelected } from '../../redux/thunks/planning-slice.thunk';


const ColumnDisplayFilter = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('translation', {keyPrefix: 'gantt_toolbar'})
    const [popoverVisible, setPopoverVisible] = useState(false);

    const saveConfig = () => {
        setPopoverVisible(false);
        dispatch(refreshPlanningSelected());
    }

    const content = (
        <div style={{ maxWidth:'600px'}}>

            <ColumnParams onOk={saveConfig} onCancel={() => setPopoverVisible(false)} />
        </div>
    );

    return (
        <div className="flex items-center h-100">
            <Popover
                placement="bottom"
                content={content}
                trigger="click"
                open={popoverVisible}
                onOpenChange={() => setPopoverVisible(!popoverVisible)}
                className="border-0 shadow-none bg-transparent"
            >
                <div className="flex items-center">
                    <ColumnsSvg /> {t('columns')}
                </div>
            </Popover>
        </div>
    );
};

export default ColumnDisplayFilter;
