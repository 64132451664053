import React, { forwardRef, useContext, useImperativeHandle, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal } from 'antd';
import { useSelector } from 'react-redux';
import FolderService from '../../services/folder.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { requestError } from '../../helpers/notification';
import { selectPlanningSelected } from '../../redux/slices/app/planning.slice';

const EditPlanningModal = forwardRef((props, ref) => {
    const {t} =useTranslation()
    const [isVisible, setVisible] = useState(false);
    const planningSelected = useSelector(selectPlanningSelected);
    const {  updatedFolderBroadcast } = useContext(PlanningContext);
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
      if (planningSelected) {
        form.setFieldsValue(planningSelected)
      }
    }, [planningSelected])

    useEffect(() => {
      if (updatedFolderBroadcast && planningSelected.id === +updatedFolderBroadcast.id) {
        form.setFieldsValue(updatedFolderBroadcast.updatedData)
      }
    }, [updatedFolderBroadcast])

    const onFinish = (values) => {
        
            FolderService.renamePlanning(planningSelected.id, values.name).then(() => {
              setVisible(false);
            })
            .catch((error) => {
                requestError(error, t('top_menu.rename_planning'));
            });
    };

    return (
        <Modal
            title={t('top_menu.rename_planning')}
            className="add-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="name"
                    label={t('general.name')}
                    rules={[{ required: true, message: t('general.required_field') }]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item className="mb-0 flex justify-end">
                        <Button type="primary" htmlType="submit">
                            {t('general.edit')}
                        </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default EditPlanningModal;
