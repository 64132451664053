const quantityUnits = [
    { label: 'm', value: 'M' },
    { label: 'm2', value: 'M2' },
    { label: 'm3', value: 'M3' },
    { label: 'To', value: 'TO' },
    { label: 'Litre', value: 'L' },
    { label: 'kg', value: 'KG' },
    { label: 'unité', value: 'SANS' },
    { label: 'Feet', value: 'FT' },
    { label: 'Feet + 100', value: 'FT100' },
    { label: 'Ft2', value: 'FT2' },
    { label: 'Ft3', value: 'FT3' },
    { label: 'Yard', value: 'YD' },
    { label: 'Yd2', value: 'YD2' },
    { label: 'Yd3', value: ' YD3' },
    { label: 'Miles', value: 'MI' },
    { label: 'Km', value: 'KM' },
];

export default quantityUnits;
